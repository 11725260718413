@use '@angular/material' as mat;
@mixin nano-manager-dialog-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));
  $default-text-color: mat.get-color-from-palette(map-get($theme, foreground), text);

  app-nano-manager-dialog {
    .delete-button {
      background-color: mat.get-color-from-palette($warn) !important;
    }

    .mat-mdc-fab.mat-accent {
      --mdc-fab-container-color: #303b41;
    }
    .mat-mdc-fab,
    .mat-mdc-mini-fab {
      box-shadow: unset;
    }

    .primary {
      color: $primary-color;
    }

    .mdc-dialog__content {
      padding-left: 0;
      padding-right: 0;

      &.no-padding {
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    .nano-data {
      font-weight: normal;
    }

    .nano-client-name,
    .slot-text,
    .room-name,
    .available-room-name,
    .room-list-name,
    .description-row,
    .input-row,
    .button-row {
      color: var(--mdc-dialog-subhead-color);
      font-weight: normal;
    }
    .waiting-nano-container {
      .mdi {
        color: $primary-color;
      }
    }

    .no-nano-text,
    .valid-date-text,
    .slot-text,
    .waiting-text-container,
    .input-text-description {
      color: map-get($theme, inactive-text);
    }

    .slot-icon {
      border: 2px solid map-get($theme, inactive-text);
    }

    .filled-slot:hover {
      background-color: map-get($theme, selected-menu);
    }

    .nano-client-data {
      span {
        color: var(--mdc-dialog-subhead-color);
      }
    }

    .mat-mdc-dialog-title {
      mat-icon {
        font-size: 18px;
        width: 18px;
        height: 18px;
      }
    }

    .no-drive,
    .no-room {
      .mdi {
        color: map-get($theme, inactive-text);
      }
    }
    .drive-list {
      :not(.unresolved) {
        &.drive-item:hover {
          background-color: map-get($theme, selected-menu);
        }
      }

      .drive-item {
        .drive-info {
          .folder-name {
            color: var(--mdc-dialog-subhead-color);
          }
        }

        .mdi {
          color: map-get($theme, inactive-text);
        }
      }
    }

    span.drive-current-path > span.trl-overwrite {
      color: map-get($theme, inactive-text);
    }

    .drive-folder-container {
      background-color: map-get($color-config, default-content-background);

      .drive-folder-name {
        color: var(--mdc-dialog-subhead-color);
      }
      .mdi-harddisk,
      .mdi-chevron-right,
      .mdi-chevron-left {
        color: map-get($theme, inactive-text);
      }
      .mdi-folder {
        color: map-get($theme, file-folder);
      }

      :not(.unresolved) {
        &.drive-folder-item:hover {
          background-color: map-get($theme, selected-menu);
        }
      }
    }

    .drive-list,
    .room-list {
      background-color: map-get($color-config, default-content-background);
    }

    .button-box {
      div {
        background-color: map-get($theme, resource-content);
      }
    }

    #nano-logs {
      color: $default-text-color;

      #nano-log-chooser {
        border-top: 1px solid map-get($theme, highlighted);
        border-bottom: 1px solid map-get($theme, highlighted);
      }

      .log-file {
        border: 1px solid map-get($theme, inactive-text);

        &.selected {
          color: $primary-color;
          border: 1px solid $primary-color;
        }
      }
    }

    #backups{
      color: $default-text-color;
    }
  }
}
