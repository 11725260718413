@use '@angular/material' as mat;
@mixin admin-add-backup-dialog-mat($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-color: mat.get-color-from-palette(map-get($color-config, primary));
  $default-text-color: mat.get-color-from-palette(map-get($theme, foreground), text);

  app-admin-add-backup-dialog {
    .room {
      &.selected {
        border: 1px solid $primary-color;
      }
    }
  }
}
